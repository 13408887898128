<template>
    <div class="gray-bg order-detail"  v-htmltit data-title="订单详情">
        <go-back-btn></go-back-btn>
        <go-cart-btn></go-cart-btn>
        <pay-type  
            :propShow="propShowPayType"
            :totalMoney="orderDetail.orderPayMoney"
            :payType="orderDetail.payType"
            @handleClickPropShow="handleClickPropShowPayType"
            @handleChangePayType="handleChangePayType"></pay-type>
        <skeleton v-if="!loading"></skeleton>
        <div v-if="loading" class="order-detail-box">
            <div class="order-detail-tit">
                <div class="tit">订单信息</div> 
                <div v-if="orderDetail.orderStatus == 0" style="color:#ee0a24">全部</div>
                <div v-if="orderDetail.orderStatus == 1" style="color:#FF413D">待支付</div>
                <div v-if="orderDetail.orderStatus == 2" style="color:#C8CACC">待处理</div>
                <div v-if="orderDetail.orderStatus == 3" style="color:#767676">已处理</div>
                <div v-if="orderDetail.orderStatus == 4" style="color:#030303">已完成</div>
            </div>
            <div class="order-detail-list">
                <div class="order-detail-item">
                    <div class="order-detail-label">订单编号：</div>
                    <div class="order-detail-con">{{orderDetail.orderno}}</div>
                </div>
                <div class="order-detail-item">
                    <div class="order-detail-label">订单备注：</div>
                    <div class="order-detail-con">{{orderDetail.orderRemark?orderDetail.orderRemark:'暂无'}}</div>
                </div>
                <div class="order-detail-item" v-if="isMore">
                    <div class="order-detail-label">下单时间：</div>
                    <div class="order-detail-con">{{orderDetail.orderTime}}</div>
                </div>
                <div class="order-detail-item borderB" style="padding-bottom:0.5rem" v-if="isMore">
                    <div class="order-detail-label">支付方式：</div>
                    <div class="order-detail-con" v-if="orderDetail.payType == 1 && sysOfficeId === '2d438ec112d442058dc16fc7debe8da6'">发货付款</div>  
                    <div class="order-detail-con" v-if="orderDetail.payType == 1 && sysOfficeId !== '2d438ec112d442058dc16fc7debe8da6'">货到付款</div>  
                    <div class="order-detail-con" v-if="orderDetail.payType == 2">微信支付</div>  
                    <div class="order-detail-con" v-if="orderDetail.payType == 3">微信转账</div>  
                    <div class="order-detail-con" v-if="orderDetail.payType == 4">支付宝</div>  
                    <div class="order-detail-con" v-if="orderDetail.payType == 5">余额支付</div>
                    <div class="order-detail-con" v-if="orderDetail.payType == 6">现场支付</div>
                </div>
                <div class="order-detail-item" style="padding-top:0.5rem" v-if="isMore">
                    <div class="order-detail-label">收货人：</div>
                    <div class="order-detail-con">{{orderDetail.deliveryInfo.consignee?orderDetail.deliveryInfo.consignee:'-'}}</div>
                </div>
                <div class="order-detail-item" v-if="isMore">
                    <div class="order-detail-label">收货电话：</div>
                    <div class="order-detail-con">{{orderDetail.deliveryInfo.phone?orderDetail.deliveryInfo.phone:'-'}}</div>
                </div>
                <div class="order-detail-item order-detail-item-address" v-if="isMore">
                    <div class="order-detail-label">收货地址：</div>
                    <div class="order-detail-con">{{orderDetail.deliveryInfo.area?orderDetail.deliveryInfo.area:'-'}} {{orderDetail.deliveryInfo.address?orderDetail.deliveryInfo.address:''}}</div>
                </div>
            </div>
            <div class="order-detail-openCloseBox" @click="handleClickLookMore">
                <div v-if="!isMore" class="order-detail-openClose">
                    <div>查看更多</div>
                    <div><i style="font-size:0.75rem" class="iconfont icon-shuangxiajiantou-"></i></div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="order-detail-goodBox">
            <div class="order-detail-good-title">
                <div>订单明细</div>
                <div style="color:#000000">{{orderDetail.sququantity}}类 / {{orderDetail.skuquantity}}件</div>
            </div>
            <div  v-for="item in orderDetail.skulist" :key="item.id">
                <div class="order-detail-good-box" v-if="item.goods_type < '2'">
                    <div class="order-detail-good-item">
                        <div class="order-detail-goodinfo">
                            <div class="order-detail-img">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4,3.6)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="order-detail-good">
                                <div class="order-detail-name">{{item.name}}</div>
                                <div class="order-detail-sku" v-if="item.standard">{{item.standard}}</div>
                            </div>
                            <div class="order-detail-right">
                                <div class="order-detail-price">{{Number(item.price).toFixed(2)}}</div>
                                <div class="order-detail-count">X{{item.quantity}}</div>
                                <div class="order-detail-priceTotal">{{Number(item.price * item.quantity).toFixed(2)}}</div>
                            </div>
                        </div>
                        <div>
                            <div class="order-confirm-other">
                                <span v-for="childItem in  item.input_attr" :key="childItem.attr_name">
                                    {{childItem.attr_name}}:{{childItem.attr_val}}
                                </span>
                            </div>
                        </div>
                        <div class="order-note">
                            <div>备注:</div>
                            <div>{{item.orderRemark?item.orderRemark:'暂无'}}</div>
                        </div>
                    </div>
                </div>
                <div class="order-detail-good-box order-detail-good-box2" v-if="item.goods_type == '2'">
                    <div class="order-detail-good-item">
                        <div class="order-detail-goodinfo">
                            <div class="order-detail-img">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4,3.6)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="order-detail-good">
                                <div class="order-detail-name">{{item.name}}</div>
                                <div class="order-detail-sku" v-if="item.standard">{{item.standard}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="order-detail-sku-box" v-if="item.glass_myopia_atrr.attr_val.length > 0">
                        <div class="order-detail-sku-list">
                            <div class="order-detail-sku-item" v-for="childItem in item.glass_myopia_atrr.attr_val" :key="childItem.id">
                                <div class="order-detail-sku-item-item">
                                    <div class="order-detail-sku-item-left">
                                        <div>[{{childItem.myopia}},{{childItem.astigmatism}}]</div>
                                        <div  class="order-detail-price">{{Number(childItem.price).toFixed(2)}}<span style="font-size:0.6rem">/{{item.unit}}</span></div>
                                    </div>
                                    <div class="order-detail-sku-item-right">
                                        <div style="color:#969799;font-size:0.7rem">x{{childItem.quantity}}</div>
                                        <div class="order-detail-price">{{Number(childItem.price *childItem.quantity).toFixed(2)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="order-note">
                            <div>备注:</div>
                            <div>{{item.orderRemark?item.orderRemark:'暂无'}}</div>
                        </div>
                    </div>
                </div>
                <div class="order-detail-good-box order-detail-good-box2" v-if="item.goods_type == '3'">
                    <div class="order-detail-good-item">
                        <div class="order-detail-goodinfo">
                            <div class="order-detail-img">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4,3.6)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="order-detail-good">
                                <div class="order-detail-name">{{item.name}}</div>
                                <div class="order-detail-sku" v-if="item.standard">{{item.standard}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="order-detail-sku-box" v-if="item.glass_myopia_atrr.attr_val.length > 0">
                        <div class="order-detail-sku-list">
                            <div class="order-detail-sku-item" v-for="(childItem,childIndex) in item.glass_myopia_atrr.attr_val" :key="childItem.id + childIndex">
                                <div class="order-detail-sku-item-item">
                                    <div class="order-detail-sku-item-left">
                                        <div>
                                            <span v-if="childItem.left_or_right_eyes !== '0'">{{ childItem.left_or_right_eyes | eyesTypeFilter }},</span>
                                            [{{childItem.myopia}},{{childItem.astigmatism}}]
                                            <span v-if="childItem.cylinder_axis">,散光轴位:{{childItem.cylinder_axis}}</span>
                                            <span v-if="childItem.pupillary_distance">,瞳距(mm):{{childItem.pupillary_distance}}</span>
                                            <span v-if="childItem.add_light">,下加光ADD:{{childItem.add_light}}</span>
                                        </div>
                                        <div  class="order-detail-price">{{Number(childItem.price).toFixed(2)}}<span style="font-size:0.6rem">/{{item.unit}}</span></div>
                                    </div>
                                    <div class="order-detail-sku-item-right">
                                        <div style="color:#969799;font-size:0.7rem">x{{childItem.quantity}}</div>
                                        <div class="order-detail-price">{{Number(childItem.price *childItem.quantity).toFixed(2)}}</div>
                                    </div>
                                </div>
                                 <!-- <div class="order-detail-sku-item-item order-detail-sku-item-item2">
                                    <div style="margin-right:0.4rem">处方图片</div>
                                    <div>
                                        <van-image fit="contain"
                                            class="img-box"
                                            :src="item.img">
                                            <template v-slot:error>
                                                <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                            </template>
                                            <template v-slot:loading>
                                                <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                            </template>
                                        </van-image>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="order-note">
                            <div>备注:</div>
                            <div>{{item.orderRemark?item.orderRemark:'暂无'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-detail-goodPrice">
                <div class="order-detail-discount-item">
                    <div>订单总额</div>
                    <div>{{Number(orderDetail.orderSumMoney).toFixed(2)}}</div>
                </div>
                <div class="order-detail-discount-item">
                    <div>运费</div>
                    <div>{{Number(orderDetail.freightMoney).toFixed(2)}}</div>
                </div>
                <div class="order-detail-discount-item" v-if="Number(orderDetail.orderSumMoney) - Number(orderDetail.orderPayMoney) > 0">
                    <div>优惠金额</div>
                    <div>{{(Number(orderDetail.orderSumMoney) - Number(orderDetail.orderPayMoney)).toFixed(2)}}</div>
                </div>
            <!--<div class="order-detail-discount-item">
                    <div>卡券</div>
                    <div>-10</div>
                </div>
                <div class="order-detail-discount-item">
                    <div>红包</div>
                    <div>-10</div>
                </div> -->
                <div class="order-detail-discount-item total-price">
                    <div class="label">实际支付</div>
                    <div class="price">{{Number(orderDetail.orderPayMoney).toFixed(2)}}</div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="order-detail-footer">
            <div class="btn-list">
                <div v-if="orderDetail.payStatus == 0 && orderDetail.documentStatus == 0" class="btn cancel" @click="handleClickCancel">取消订单</div>
                <!-- <div class="btn ok" @click="handleClickAngin">再来一单</div> -->
                <!-- <div v-if="orderDetail.orderStatus == 3" class="btn shou" @click="handleClickConfirm">确认收货</div> -->
                <div v-if="orderDetail.payStatus == 0" class="btn shou" @click="handleClickPay">去支付</div>
            </div>
        </div>
    </div>
</template>
<script>
import './index.less'
import {ImagePreview} from "vant";
import goBackBtn from "@/view/Public/goBackBtn"
import goCartBtn from "@/view/Public/goCartBtn"
import skeleton from "./component/skeleton"
import payType from "@/view/Public/payType"
import { orderDetailInfo, orderCancel,orderPayAgain,OrderUpdatePayType } from  "@/apis/api"
import { formatTime ,wxConfig} from "@/libs/utils"
import bshop_global from "@/libs/global"
import defaultImg from "@/assets/image/default.png"
import { mapActions } from 'vuex'

// 左眼 右眼
const eyesTypeMap = {
    '0': {
        value: '0',
        name: '不区分'
    },
    '1': {
        value: '1',
        name: '左眼'
    },
    '2': {
        value: '2',
        name: '右眼'
    }
}
export default {
    name:"order-detail",
    components:{ skeleton ,goBackBtn ,goCartBtn, payType },
    data(){
        return{
            sysOfficeId:localStorage.getItem('sysOfficeId'),
            id:"",
            isCancel:false,
            defaultImg:defaultImg,
            loading:true,
            isMore:false,
            orderDetail:{},
            allCount:0,  //总数量
            // 传到支付方式弹窗组件的数据 start
            propShowPayType:false,
            // 传到支付方式弹窗组件的数据 end
            paymentType:1, // 切换支付方式
            difference:'', // 差额 钱
            difference_type:'', // 差额 用什么支付方式
        }
    },
    created(){
        this.orderDetail.id = this.$route.query.id
        this.id = this.$route.query.id
    },
    beforeRouteLeave(to,from,next){
        if(this.isCancel){
            if(to.name == 'order'){
                to.meta.isfresh = true
            }
        }else{
            if(to.name == 'order'){
                to.meta.isfresh = false
            }
        }
        next()
    },
    mounted() {
        setTimeout(()=>{
            this.loading = true
        },1000)
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0,0);
        this.getOrderDetailInfo();
    },
    filters: {
        eyesTypeFilter (state) {
            return eyesTypeMap[state].name
        }
    },
    methods:{
        ...mapActions(['getNoPayCount']),
        //获取订单详情
        getOrderDetailInfo(){
            let toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let data = { 
                orderId:this.id
            }
            orderDetailInfo(data).then(res=>{
               toast.clear();
                if(res.code == 0){
                    res.res.orderTime = formatTime(res.res.orderTime,'Y-M-D h:m:s')
                    this.orderDetail = res.res;
                    let skulist = res.res.skulist;
                }else if(res.code == 115){
                    // 后台作废订单
                    this.$toast({
                        message: res.msg,
                        forbidClick: true
                    })
                    setTimeout(()=>{
                        this.isCancel = true
                        this.$router.go(-1)
                    },2000)
                    
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //查看更多
        handleClickLookMore(){
            this.isMore = !this.isMore
        },
        //取消订单
        handleClickCancel(){
            if(this.orderDetail.placeOrderType === '2') {
                this.$toast('请联系平台客服，取消代下订单')
                return false
            }
            this.$dialog.confirm({
                width:'88%',
                title: '提示',
                message: '确认取消吗？'
            }).then(() => {
                let params = {
                    order_id:this.id,
                    customer_master_id:localStorage.getItem('customermasterId')
                }
                orderCancel(params).then(res=>{
                    if(res.code == 0){
                        this.getOrderDetailInfo()
                        this.$toast("取消成功") 
                        this.isCancel = true
                        this.$router.go(-1)
                        this.getNoPayCount()
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }).catch(()=>{})
        },
        //再来一单
        handleClickAngin(){
            this.$toast("暂未开放")
                return;
            this.$toast("已加入购物车")
        },
        //确认收货
        handleClickConfirm(){
            this.$toast("暂未开放")
                return;
            this.$toast("确认收货")
        },
        //点击去支付
        handleClickPay(){
            this.propShowPayType = true;
        },
        // 再次支付接口
        apiAgainPay(){
            let toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let params = {
                order_id:this.id,
                pay_type:this.paymentType.type,
                difference:this.difference, //差额 差的钱
                difference_type: this.difference_type, // 差额 用什么支付方式
                realpay:this.orderDetail.orderPayMoney
            }
            orderPayAgain(params).then(res=>{
                toast.clear();
                if(res.code == 0){
                    if(this.paymentType.type == 1){
                        this.$router.replace({ name:"payment-success" })
                    }else if(this.paymentType.type == 2){
                        wxConfig(res.res.wxJsApiParam);
                        //将返回的信息放在缓存中 以便重新支付时获取
                        localStorage.setItem("againRes",JSON.stringify(res.res.wxJsApiParam))
                    }else if(this.paymentType.type == 3){
                    }else if(this.paymentType.type == 4){
                        window.location.href =  bshop_global.bShopUrl + "/#/payinfo?code=" + res.res.orderId+"&customerId=" + localStorage.getItem('customerId') +"&sysOfficeId="+ localStorage.getItem('sysOfficeId');
                    } else if(this.paymentType.type == 5){
                        this.$router.replace({ name:"payment-success"})
                    }
                    this.getNoPayCount()
                }else if(res.code == 115){
                    // 后台作废订单
                    this.$toast({
                        message: res.msg,
                        forbidClick: true
                    })
                    setTimeout(()=>{
                        this.isCancel = true
                        this.$router.go(-1)
                    },2000)
                }else{
                    this.$toast({
                        message: res.msg
                    })
                    this.propShowPayType = false
                    
                    let data = { 
                        orderId:this.id
                    }
                    orderDetailInfo(data).then(res=>{
                        if(res.code == 0){
                            res.res.orderTime = formatTime(res.res.orderTime,'Y-M-D h:m:s')
                            this.orderDetail = res.res;
                            let skulist = res.res.skulist;
                        }else if(res.code == 115){
                            // 后台作废订单
                            this.$toast({
                                message: res.msg,
                                forbidClick: true
                            })
                            setTimeout(()=>{
                                this.isCancel = true
                                this.$router.go(-1)
                            },2000)
                            
                        }else{
                            this.$toast(res.msg)
                        }
                    })
                }
            })
        },
        // 切换支付方式接口
        apiSetPayType(){
            let toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let params = {
                id:this.id,
                pay_type:this.paymentType.type
            }
            OrderUpdatePayType(params).then(res=>{
                toast.clear();
                if(res.code == 0){
                    // if(this.paymentType.type == 1){
                        // 货到付款 直接 成功
                        this.$toast({
                            message:'订单备货中，请尽快付款',
                            duration:3000
                        })
                        this.isCancel = true // 为了返回的时候刷新一下
                        this.$router.go(-1)
                    // }else{
                    //     window.location.href =  bshop_global.bShopUrl + "/#/payinfo?code=" + this.id +"&customerId=" + localStorage.getItem('customerId') +"&sysOfficeId="+ localStorage.getItem('sysOfficeId');
                    // }
                }else if(res.code == 115){
                    // 后台作废订单
                    this.$toast({
                        message: res.msg,
                        forbidClick: true
                    })
                    setTimeout(()=>{
                        this.isCancel = true
                        this.$router.go(-1)
                    },2000)
                    
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //预览图片
        handleClickPreview(img){
            ImagePreview({
                images:[img],
                showIndex:false
            });
        },
         //切换支付方式弹窗组件的propShow  start
        handleClickPropShowPayType(v){
            this.propShowPayType = v;
        },
        //切换支付方式
        handleChangePayType(v, surplusPayInfo){
            this.paymentType = v;
            this.difference = surplusPayInfo.difference
            this.difference_type = surplusPayInfo.difference_type
            if( v.type == 1){
                // 如果要切换成发货付款
                this.apiSetPayType()
            }else{
                this.apiAgainPay() // 重新支付
            }
        }
        //切换支付方式弹窗组件的propShow end
    }
}
</script>