<template>
    <div class="skeleton-box">
        <div class="skeleton-order-detail">
            <div class="skeleton-order-detail-tit">
                <van-skeleton :row="1" row-width="4rem" />
                <van-skeleton :row="1" row-width="2.5rem" />
            </div>
            <div class="skeleton-order-detail-con">
                <van-skeleton :row="1" row-width="16rem" />
                <van-skeleton style="margin-top:0.5rem;" :row="1" row-width="16rem" />
            </div>
        </div>
        <div class="skeleton-good-detail">
            <div class="skeleton-good-detail-tit">
                <van-skeleton :row="1" row-width="3rem" />
                <van-skeleton :row="1" row-width="5rem" />
            </div>
            <div class="skeleton-good-detail-con" v-for="item in data" :key="item">
                <div class="skeleton-good-detail-con-list">
                    <div style="width:30%;margin-right:0.5rem">
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                    </div>
                    <div style="width:50%;margin-right:0.5rem">
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" style="margin-top:1rem;" row-width="100%" />
                    </div>
                    <div style="width:15%;">
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                        <van-skeleton :row="1" row-width="100%" />
                    </div>
                </div>
                <div class="skeleton-good-detail-con-other">
                     <van-skeleton :row="1" row-width="100%" />
                </div>
            </div>
        </div>
        <div class="skeleton-price-detail">
            <div>
                <van-skeleton style="margin-top:0.5rem" :row="1" row-width="6rem" />
                <van-skeleton style="margin-top:0.5rem" :row="1" row-width="6rem" />
                <van-skeleton style="margin-top:0.5rem" :row="1" row-width="6rem" />
            </div>
        </div>
    </div>
</template>
<script>
import "./index.less"
export default {
    name:"skeleton",
    data(){
        return{
            data:[0,1]
        }
    }
}
</script>